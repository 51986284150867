body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-label {
  font-weight: 600;
}

/* Surcharge de style pour la liste des formulaires */

.survey-list .dt-actions-filters, .subform-list .dt-actions-filters {
  padding-top: 30px;
}
.survey-list .dt-actions,  .subform-list .dt-actions {
  top: -30px;
  position: relative;
}
.survey-list .dt-actions .btn,  .subform-list .dt-actions .btn {
  float: right;
}
.survey-list .dt-table-content .btn-outline-primary, .subform-list .dt-table-content .btn-outline-primary {
  margin-right: 10px;
}
.survey-list .uil.uil-xl {
  font-size: 20px;
}


/* Surcharge pour le bouton close de la modal qui n'a pas de style */

.modal-header button.close {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #888;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.4rem;
}
.modal-header button.close span {
  font-size:  20px;
}
.modal-header button.close span.sr-only {
  display: none;
}

.modal-body p {
  font-family: "Segoe UI" !important;
}

.react-date-picker {
  display: block;
  width: 100%;
}

.react-date-picker .react-date-picker__wrapper
{
font-size: 13px;
color: #343a40;
background-color: #fff;
background-clip: padding-box;
border: 1px solid rgba(0,0,0,.1);
border-radius: 0.25rem;
line-height: 16px;
    padding: 6px 10px 5px;
    font-size: 1.1rem;
    line-height: 1.5;
    padding: 0.5rem 1.1rem;
    font-weight: 400;

}